import React, { useState } from "react";

const CareerPage = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div
      id="career"
      className="bg-black text-white min-h-screen w-full flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8"
    >
      <div>
        <h2 className=" bg-gradient-to-r from-orange-500 to-orange-300 bg-clip-text text-transparent mt-6 mb-12 text-4xl lg:text-6xl font-extrabold">
          CAREER
        </h2>
      </div>
      <div className="flex flex-col md:flex-row container">
        <div className="flex px-6 lg:px-2 sm:mx-auto sm:max-w-md  ">
          <div className="flex flex-col justify-start items-center">
            <h2 className="mt-6 text-xl lg:text-3xl text-center font-extrabold">
              What is it like <div>working with us?</div>
            </h2>

            <p className="mt-2 text-justify text-md">
              We believe that true innovation comes from embracing diversity and
              fostering a happy team. We are a vibrant collective of creative
              minds from diverse backgrounds, working together to create IT
              applications that address real-world problems. Our inclusive and
              collaborative environment enables us to leverage the unique
              perspectives and talents of each team member.
            </p>
            <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="text-center">
                <button
                  onClick={openModal}
                  className="bg-orange-500 hover:bg-orange-700 text-white font-bold text-lg mb-14 md:mb-0 py-2 px-8 lg:text-2xl lg:py-4 lg:px-16 rounded-full transition duration-300 ease-in-out"
                >
                  Join Us
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex px-6 lg:px-2 sm:mx-auto sm:max-w-md">
          <div className="text-start">
            <h2 className="mt-6 text-xl lg:text-3xl text-center font-extrabold">
              Perks
            </h2>
            <div className="mt-2 text-md text-justify">
              <div className="mb-4">
                <span className="mt-2 text-md leading-5">
                  Mentorship and Guidance : Mentorship and guidance for new
                  employees to ease their onboarding process and help them
                  settle into their roles smoothly.
                </span>
              </div>
              <div className="mb-4">
                <span className="mt-2 text-md leading-5">
                  Work from Home : Embracing the changing work landscape, we
                  offer the option for our employees to work from the comfort of
                  their homes.
                </span>
              </div>
              <div className="mb-4">
                <span className="mt-2 text-md text-startleading-5">
                  Creative Environment : We foster a dynamic and innovative
                  workplace where creative ideas are welcomed and nurtured.
                </span>
              </div>
              <div className="mb-4">
                <span className="mt-2 text-md leading-5">
                  Flexible Work Schedule : Our flexible work schedule allows our
                  employees to manage their time effectively and create a
                  healthy harmony between personal and professional commitments.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={closeModal}
        >
          <div
            className="bg-black bg-opacity-95 rounded-lg p-2 lg:p-8 w-5/6 md:w-1/2"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-orange-500 text-xl font-semibold mb-4">
              Apply for a Position
            </h2>
            <p className="text-white text-sm mb-4">
              We are open for Flutter Developer, AI/ML Researcher, and Deep
              Learning Researcher.
            </p>
            <form
              action="https://formspree.io/f/mqkvaoow"
              method="POST"
              className="space-y-6"
            >
              <div className="mb-4">
                <label
                  htmlFor="position"
                  className="text-white text-sm block mb-1"
                >
                  Position:
                </label>
                <input
                  type="text"
                  id="position"
                  name="position"
                  className="bg-gray-800 rounded-full py-2 px-4 w-full"
                  placeholder="Enter the position you're applying for"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="motivation"
                  className="text-white text-sm block mb-1"
                >
                  Motivation to join:
                </label>
                <textarea
                  id="motivation"
                  name="motivation"
                  className="bg-gray-800 rounded-lg py-2 px-4 w-full h-20 resize-none"
                  placeholder="Enter your motivation to join"
                ></textarea>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="contactNumber"
                  className="text-white text-sm block mb-1"
                >
                  Contact Number:
                </label>
                <input
                  type="tel"
                  id="contactNumber"
                  name="contactNumber"
                  className="bg-gray-800 rounded-full py-2 px-4 w-full"
                  placeholder="Enter your contact number"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="text-white text-sm block mb-1"
                >
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="bg-gray-800 rounded-full py-2 px-4 w-full"
                  placeholder="Enter your email address"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="text-white text-sm block mb-1"
                >
                  Resume Link:
                </label>
                <input
                  type="text"
                  id="resume link"
                  name="resume link"
                  className="bg-gray-800 rounded-full py-2 px-4 w-full"
                  placeholder="Kindly ensure link is public"
                />
              </div>
              <button
                type="submit"
                className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
              >
                Submit Application
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CareerPage;
