import React, { useEffect, useState } from "react";

const Loader = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [className, setClassName] = useState(
    "fixed h-screen w-screen bg-black top-0 transition-all ease-linear duration-300 flex justify-center items-center z-50"
  );

  const texts = ["IMAGINE", "BUILD", "PRODUCE"];

  useEffect(() => {
    // Set initial timeout to change the class name after 1000 milliseconds (1 second)
    const initialTimeoutId = setTimeout(() => {
      console.log("Timeout successful");
      // Update the class name
      setClassName(
        "fixed h-screen w-screen bg-black -top-[200vh] transition-all ease-linear duration-300 flex justify-center items-center z-50"
      );
    }, 3000);

    // Cleanup function to clear the initial timeout if the component unmounts before the timeout
    return () => clearTimeout(initialTimeoutId);
  }, []);

  useEffect(() => {
    // Set timeout to increment the index every 3 seconds
    const timeoutId = setTimeout(() => {
      // Increment the index
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 1000);

    // Cleanup function to clear the timeout if the component unmounts before the timeout
    return () => clearTimeout(timeoutId);
  }, [currentIndex]);

  const getClassName = (index) => {
    // Calculate the opacity based on the current index
    const opacity = currentIndex === index ? 1 : 0;
    // Set the initial position off-screen for the fading effect
    const topPosition = currentIndex === index ? 0 : -100;
    // Set the display property to 'none' for elements that are not currently active
    const display = currentIndex === index ? "block" : "hidden";

    // Delay for 1 second after each text is displayed
    const delay = index > 0 ? 500 : 0;
    // Linear gradient styles
    // const gradientStyles = `linear-gradient(45deg, #FFD700, #FF8C00)`;

    return (
        `text-5xl font-normal lg:text-7xl xl:text-8xl text-orange-500 transition-all ease-linear duration-1000` +
        ` opacity-${opacity} -top-${topPosition} ${display} delay-${delay} bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500`
      );
  };

  return (
    <div>
      <div className={className}>
        {texts.map((text, index) => (
          <div key={index} className={getClassName(index)}>
            {text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Loader;
