import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8 px-4">
      <div className="container mx-auto">
        <div className="flex flex-col items-center md:items-start md:flex-row justify-evenly">
          <div className="flex flex-col items-center mb-6 md:mb-0">
            <h3 className="text-lg font-semibold md:mb-4">Contact Us</h3>
            <p>Address: Pilibhit, Uttar Pradesh 262122, IN</p>
            <p>Phone: +91 8057233599</p>
            <p>Email: support@venuratech.com</p>
          </div>
          <div className="flex flex-col items-center mb-6 md:mb-0">
            <h3 className="text-lg font-semibold md:mb-4">Follow Us</h3>
            <ul>
              <li>LinkedIn</li>
              {/* Add other social media links here */}
            </ul>
          </div>
          <div className="flex flex-col items-center mb-6 md:mb-0">
            <h3 className="text-lg font-semibold md:mb-4">Navigation</h3>
            <ul className="flex flex-col items-center">
              <li>Home</li>
              <li>About</li>
              <li>Career</li>
              <li>Our Team</li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-700 mt-8 pt-8 text-center">
          <p>© 2024 Venuratech. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
