import React from 'react'
import "./Page5.css"

export default function Page5() {
  return (
    <div id="section-5" className=''>
      <div className="section-technologies" id="tech">
        <div className="section-headings">
          <h1 className="heading-stroke-only">Technologies</h1>
          <div>We use</div>
        </div>

        <div className="row">
          <div className="col">
            <div
              className="tech-icon-img-1"
            > </div>
          </div>
          <div className="col">
            <div
              className="tech-icon-img-2"
            > </div>
          </div>
          <div className="col">
            <div 
              className="tech-icon-img-3"
            > </div>
          </div>
          <div className="col">
            <div
              className="tech-icon-img-4"
            > </div>
          </div>
          <div className="col">
            <div
              className="tech-icon-img-5"
            > </div>
          </div>
          <div className="col">
            <div
              className="tech-icon-img-6"
            > </div>
          </div>
          <div className="col">
            <div
              className="tech-icon-img-7"
            > </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div
              className="tech-icon-img-8"
            > </div>
          </div>
          <div className="col">
            <div
              src="./icons/nodejs.svg"
              alt="img"
              className="tech-icon-img-9"
            > </div>
          </div>
          <div className="col">
            <div
              className="tech-icon-img-10"
            > </div>
          </div>
        </div>


        <div className="row">
          <div className="col">
            <div 
              className="tech-icon-img-11" 
            > </div>
          </div>
          <div className="col ">
            <div
              className="tech-icon-img-12 img-google-cloud "
            > </div>
          </div>
          <div className="col">
            <div
              className="tech-icon-img-13"
            > </div>
          </div>
        </div>
        <div className="row technologies-row-last" >
          <div className="col">
            <div 
              className="tech-icon-img-14" 
            />
          </div>
          <div className="col ">
            <div
              className="tech-icon-img-15"
            > </div>
          </div>
          <div className="col">
            <div
              className="tech-icon-img-16"
            > </div>
          </div>
          <div className="col">
            <div
              className="tech-icon-img-17"
            > </div>
          </div>
          <div className="col">
            <div
              className="tech-icon-img-18"
            > </div>
          </div>
        </div>
      </div>
        
    </div>
  )
}
