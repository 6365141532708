import React from "react";
import styles from "./style.module.scss";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import Nav from "./Nav/Nav";
export default function Header() {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="max-w-screen">
      <div className="container flex flex-row justify-between">
        <div className={styles.logo}>
          {" "}
          <img src="image/logo.png" alt="" className="z-50" />
        </div>
        <div
          onClick={() => {
            setIsActive(!isActive);
          }}
          className={styles.button}
        >
          <div
            className={`${styles.burger} ${
              isActive ? styles.burgerActive : ""
            }`}
          ></div>
        </div>
      </div>

      <AnimatePresence mode="wait">{isActive && <Nav />}</AnimatePresence>
    </div>
  );
}
