// import React, { useState } from "react";
// import Header from "../Header/Header";
// import Loader from "../Loader/Loader";
// import bgVideo from "../../media/470bfcca.mp4";
// import { motion } from "framer-motion";
// import styles from "./page.module.scss";
// import useMousePosition from "../MousePosition/useMousePosition";

// const Home = () => {
//   const [isHovered, setIsHovered] = useState(false);
//   const { x, y } = useMousePosition();
//   const size = isHovered ? 400 : 40;

//   return (
//     <div>
//       {/* <Loader /> */}
//       <div className="bg-black h-screen flex justify-center m-0 w-[99.2vw]">
//         <Header />
//         <div className="absolute w-[90vw] right-0">
//           <video
//             className="w-full"
//             style={{ transform: "scaleX(-1)" }}
//             loop
//             autoPlay
//             muted
//             // src="https://download-video.akamaized.net/v3-1/playback/fb219548-5285-4eca-9602-81fa79af7861/470bfcca?__token__=st=1705085792~exp=1705100192~acl=%2Fv3-1%2Fplayback%2Ffb219548-5285-4eca-9602-81fa79af7861%2F470bfcca%2A~hmac=0911f842a39b69943d103894869170d59a17fab0619e59027dfbcdd3bfaa7057&r=dXMtZWFzdDE%3D"
//             src={bgVideo}
//           ></video>
//         </div>
//         <div className="w-[90%] h-screen flex flex-col justify-center items-start z-10">
//           <div className="p-2 mt-44 font-semibold text-5xl md:text-8xl lg:text-[8rem] xl:text-[10rem] 2xl:text-[12rem] bg-clip-text text-transparent bg-gradient-to-r from-orange-600 via-red-400 to-orange-600">
//             VENURATECH <br /> SOLUTIONS
//           </div>

//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;

import React, { useState } from "react";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import bgVideo from "../../media/470bfcca.mp4";
import { motion } from "framer-motion";
import styles from "./page.module.scss";
import useMousePosition from "../MousePosition/useMousePosition";

const Home = () => {
  const [isHovered, setIsHovered] = useState(false);


  const { x, y } = useMousePosition();
  const size = isHovered ? 400 : 40;

  

  return (
    <div id="home" className="relative top-0 bg-black">
      <Loader />
      <div className=" min-h-screen flex justify-center m-0 w-[99.2vw] overflow-hidden">
        <Header />
        <div className="absolute lg:w-[90vw] right-0 w-[150vw]">
          <video
            className="w-full rig"
            style={{ transform: "scaleX(-1)" }}
            loop
            autoPlay
            muted
            src={bgVideo}
          ></video> 
        </div>
        <div
          className={`w-[100%] h-screen flex flex-col justify-center items-start z-10 ${styles.main}`}
        >
          <motion.div
            className={styles.mask}
            animate={{
              WebkitMaskPosition: `${x - size / 2}px ${y - size / 2}px`,
              WebkitMaskSize: `${size}px`,
            }}
            transition={{ type: "tween", ease: "backOut", duration: 0.5 }}
          >
            <p
              className={`p-2 mt-0 font-semibold text-[2.2rem] md:text-[3rem] lg:text-[4rem] xl:text-[6rem] 2xl:text-[6rem] bg-clip-text text-transparent bg-gradient-to-r from-black to-stone-950`}
              onMouseEnter={() => {
                setIsHovered(true);
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
            >
              {isHovered
                ? "Empowering Possibilities, Rebuilding Realities"
                : ""}
            </p>
          </motion.div>
          <div className={styles.body}>
            <p className="mx-4 px-10 z-20 p-2 mt-44 font-semibold text-4xl md:text-7xl lg:text-[6rem] xl:text-[8rem] 2xl:text-[10rem] bg-clip-text text-transparent bg-gradient-to-r from-orange-600 via-red-400 to-orange-600">
              <div>
                VENURATECH <br /> SOLUTIONS
              </div>
            </p>
          </div>
        </div>

        {/* button component */}
      </div>
    </div>
  );
};

export default Home;
