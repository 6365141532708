import React from "react";

import { motion } from "framer-motion";
import { LampContainer } from "../ui/lamp";
import { StickyScroll } from "../ui/sticky-scroll-reveal";
import video from "../../media/AbstractBokehOctagonFootageVideoYoutubeOutro-ezgif.com-resize-video.mp4";

const content = [
  {
    title: "Innovative IT Solutions",
    description:
      "At Venuratech Solutions, we harness the power of advanced technologies to develop inventive IT applications that tackle real-life obstacles. Our solutions drive positive social change and empower individuals, businesses, and communities to thrive in a dynamic environment.",
  },
  {
    title: "Cutting-edge Technology",
    description:
      "Discover the latest in cutting-edge technology with Venuratech Solutions. Our team specializes in leveraging advanced technologies to create revolutionary solutions that truly matter. From optimizing operations to improving productivity, we're committed to equipping you with the tools you need to succeed.",
  },
  {
    title: "Empowering Success",
    description:
      "Join Venuratech Solutions in our mission to empower success. Our innovative IT solutions are designed to equip individuals, businesses, and communities with the necessary resources to thrive in today's rapidly evolving landscape. Experience the difference with Venuratech Solutions.",
  },
  {
    title: "Revolutionizing Problem-Solving",
    description:
      "Experience the power of innovation with Venuratech Solutions. Our team is dedicated to revolutionizing problem-solving by developing cutting-edge IT solutions aimed at overcoming real-world challenges. Let us help you drive positive change and achieve your goals.",
  },
];

const Revolutionalizing = () => {
  return (
    <div className="h-fit w-full bg-black">
      <LampContainer>
        <motion.h1
          initial={{ opacity: 0.5, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
          }}
          className="mt-8 bg-gradient-to-br from-slate-300 to-slate-500 py-4 bg-clip-text text-center text-4xl font-medium tracking-tight text-transparent md:text-7xl"
        >
          Revolutionizing <br /> the World
        </motion.h1>
      </LampContainer>
      <div className="whatWeDo flex flex-col md:flex-row justify-evenly relative bg-black">
        {/* <StickyScroll content={content} /> */}
        <div className="left mx-6 md:mx-0 md:w-2/5 top-0 left-0 md:sticky  flex md:h-screen">
          <div className=" bg-cover w-full h-fit md:absolute md:top-[25%] rounded-xl overflow-hidden">
            <video
              className="w-full  "
              loop
              autoPlay
              muted
              src={video}
            ></video>{" "}
          </div>
        </div>
        <div className="right mx-6 md:mx-0 md:w-1/2 h-fit flex justify-center">
          <div>
            {content.map((item, index) => (
              <div key={item.title + index} className="my-12 lg:my-24">
                <motion.h2
                  // initial={{
                  //   opacity: 0,
                  // }}
                  className="text-xl lg:text-3xl font-bold text-slate-100"
                >
                  {item.title}
                </motion.h2>
                <motion.p
                  // initial={{
                  //   opacity: 0,
                  // }}
                  className="text-sm lg:text-xl text-slate-300 max-w-md mt-8"
                >
                  {item.description}
                </motion.p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Revolutionalizing;
