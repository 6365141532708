import React, { useState } from "react";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div id="contact" className="section-contact-us text-white min-h-screen flex flex-col justify-center items-center py-12 xl:px-6 lg:px-8">
      <div className="max-w-lg w-full px-6 lg:px-auto">
        <h2 className="text-white text-4xl lg:text-6xl font-extrabold text-center mb-16">
          Contact Us
        </h2>
        <form action="https://formspree.io/f/mqkvaoow" method="POST" className="space-y-6">
          <div>
            <label
              htmlFor="name"
              className="block text-md lg:text-xl font-medium text-orange-400 mb-2"
            >
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              autoComplete="name"
              placeholder="Your name"
              className="bg-gray-800 rounded-xl focus:ring-black focus:border-black  py-2 px-4 w-full"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-md lg:text-xl font-medium text-orange-400 mb-2"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              placeholder="name@gmail.com"
              className="bg-gray-800 rounded-xl focus:ring-black focus:border-black  py-2 px-4 w-full"
            />
          </div>
          <div>
            <label
              htmlFor="message"
              className="block text-md lg:text-xl font-medium text-orange-400 mb-2"
            >
              Query/Message
            </label>
            <textarea
              id="message"
              name="message"
              rows={4}
              className="bg-gray-800 rounded-xl focus:ring-black focus:border-black  py-2 px-4 w-full mb-8"
              placeholder="Enter the position you're applying for"
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-xl text-md lg:text-xl font-medium border-white hover:text-black hover:bg-white transition duration-300 ease-in-out  text-white   focus:outline-none focus:ring-2 focus:ring-offset-5 focus:ring-orange-700"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
