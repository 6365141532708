import React, { useState } from 'react';
import styles from './style.module.scss';
import { motion } from 'framer-motion';
import { menuSlide } from '../anim';
import Link from './link/index';

const navItems = [
  {
    title: 'Home',
    href: '#home',
  },
  {
    title: 'About Us',
    href: '#about',
  },
  {
    title: 'Career',
    href: '#career',
  },
  {
    title: 'Contact',
    href: '#contact',
  },
];

export default function Nav() {
  const [selectedIndicator, setSelectedIndicator] = useState('');

  return (
    <motion.div
      variants={menuSlide}
      initial="initial"
      animate="enter"
      exit="exit"
      className={styles.menu}
    >
      <div className={styles.body}>
        <div onMouseLeave={() => setSelectedIndicator('')} className={styles.nav}>
          <div className={styles.header}>
            <p className='text-xl lg:text-3xl'>Navigation</p>
          </div>
          {navItems.map((data, index) => (
            <Link
              key={index}
              data={{ ...data, index }}
              isActive={selectedIndicator === data.href}
              setSelectedIndicator={setSelectedIndicator}
            />
          ))}
        </div>
        <div className={styles.footer}>
          {/* <a href="#">Awwwards</a>
          <a href="#">Instagram</a>
          <a href="#">Dribble</a> */}
          <a href="https://www.linkedin.com/company/venuratech-solutions">LinkedIn</a>
        </div>
      </div>
    </motion.div>
  );
}
