import React from "react";
import { CardBody, CardContainer, CardItem } from "../ui/3d-card";
import { BackgroundGradientAnimation } from "../ui/background-gradient-animation";
import { BentoGridThirdDemo } from "../GridSection/GridSection.jsx";
import styles from "./about.module.scss";

const data = [
  {
    title: "Data Driven Business Consulting",
    description:
      "Unleashing data science and machine learning, our consulting services forge data-driven strategies, empowering companies to anticipate trends and make informed decisions. Welcome the future of informed possibilities.",
    src: "analysis.png",
  },
  {
    title: "Artificial Intelligence for Social Impact",
    description:
      "Harnessing the potency of AI to champion pressing social causes. We develop AI-driven solutions, from cutting-edge healthcare diagnostics and personalized learning to the artful optimization of resources for nonprofit organizations.",
    src: "artificial.png",
  },
  {
    title: "Education Technology Solutions",
    description:
      "Crafting transformative technology products that elevate learning experiences. With each creation, we bridge the gaps in education and finance, opening the gates to quality learning for all—regardless of age, background, or ability.",
    src: "learning.png",
  },
];

const WhatDoWeDo = () => {
  return (
    <div id="about" className="h-fit w-full bg-black overflow-hidden flex flex-col items-center justify-start ">
      <div className="  h-[60vh]  flex justify-center items-center z-[1]">
        <div className="flex  justify-center items-baseline overflow-hidden ">
          <div className="  inset-0 lg:mx-[10vw] flex flex-col items-center justify-center font-bold px-4 pointer-events-none text-3xl text-center md:text-4xl lg:text-7xl">
            <div className="bg-gradient-to-r from-orange-500 to-orange-300 bg-clip-text text-transparent text-xl lg:text-3xl ">
              What is Venuratech?
            </div>
            <div className="text-white font-medium text-center text-lg  lg:text-3xl mt-8 ">
              Venuratech Solutions is dedicated to developing innovative IT
              solutions to overcome real-world challenges.
            </div>
            <div
              className="text-white h-fit  hover:cursor-pointer"
              // onClick={handleScrolLDown}
            >
              <div className="flex flex-row">
                <button className={styles.btn}></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-white w-[90%] h-[60vh] lg:container flex flex-col justify-center items-center mb-4">
        <BackgroundGradientAnimation className="flex justify-center items-center overflow-hidden ">
          <div className="absolute z-50 inset-0 lg:mx-[10vw] flex flex-col items-center justify-center text-white font-bold px-4 pointer-events-none text-3xl text-center md:text-4xl lg:text-7xl">
            <div className="bg-gradient-to-r from-orange-500 to-orange-300 bg-clip-text text-transparent opacity-85  text-xl lg:text-3xl mb-8">
              What do we do?
            </div>
            <div className="text-white font-medium text-center text-lg  lg:text-3xl mb-8">
              At our core, we're more than just problem solvers. We're dedicated
              to building a vibrant, inclusive community centered around
              respect, communication, and a shared vision for positive change.
            </div>
          </div>
        </BackgroundGradientAnimation>
      </div>

      <div className="container h-fit text-white flex flex-col md:flex-row md:flex-wrap items-center justify-evenly mb-20">
        {data.map((key, index) => (
          <div
            key={index}
            className="container lg:border-white  lg:border-2 md:w-[300px] lg:h-[600px] lg:w-[380px] h-fit mx-4 flex flex-col justify-center items-center rounded-3xl w-[100%] px-4 "
          >
            <CardContainer className="inter-var  ">
              <CardBody className="flex flex-col justify-center h-fit items-center md:w-[300px] lg:h-[600px] lg:w-[380px] w-[90%] relative group/card dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1]  rounded-3xl px-8 md:p-10 border">
                <CardItem
                  translateZ="50"
                  className="mt-8 xl:mt-20 text-lg lg:text-2xl font-semibold text-center"
                >
                  {key.title}
                </CardItem>
                <CardItem
                  as="p"
                  translateZ="60"
                  className="text-neutral-500 text-md max-w-sm mt-2 dark:text-neutral-300 text-justify"
                >
                  {key.description}
                </CardItem>
                <CardItem translateZ="100" className="w-full   mt-4 mb-12 md:mb-0">
                  <img
                    src={`/image/${key.src}`}
                    // width={280}
                    height={0}
                    alt="image"
                    className="filter invert md:w-2/3 md:m-8 w-32 mx-auto object-cover self-center rounded-4xl group-hover/card:shadow-xl"
                  />
                </CardItem>
              </CardBody>
            </CardContainer>
          </div>
        ))}
      </div>

      {/* card  */}
      <BentoGridThirdDemo />
    </div>
  );
};

export default WhatDoWeDo;