import React from "react";
import Home from "./components/Home/Home.jsx";
import OurTeam from "./components/WhatWeDo/OurTeam.jsx";
import WhatDoWeDo from "./components/WhatDoWeDo/WhatDoWeDo.jsx";
import Revolutionalizing from "./components/Revolutionalizing/Revolutionalizing.jsx";
import CareerPage from "./components/Career/Career.jsx";
import { BentoGridThirdDemo } from "./components/GridSection/GridSection.jsx";
import Page5 from "./components/Page 5/Page5.jsx";
import ContactUs from "./components/ContactUs/ContactUs.jsx";
import Footer from "./components/Footer/Footer.jsx";
const App = () => {
  return (
    <div className="max-w-screen">
      <Home />
      <Revolutionalizing /> 

      {/* <AboutUs /> */}
      <WhatDoWeDo />
      <Page5 />
      <CareerPage />
      <OurTeam />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default App;
