import { cn } from "../../utils/cn";
import React from "react";
import { BentoGrid, BentoGridItem } from "../ui/bento-grid";
import poverty from "../../media/Extreme-Poverty-in-India-530x353-2.jpg";
import ethicalAI from "../../media/ethicalAI.jpg";
import custom from "../../media/CustomResearchProjects.jpg";
import proofreading from "../../media/How-AI-Reshapes-The-Healthcare-System-In-The-US.png.webp";
import deployment from "../../media/Point5deployment.jpg";

import {
  IconBoxAlignRightFilled,
  IconClipboardCopy,
  IconFileBroken,
  IconSignature,
  IconTableColumn,
} from "@tabler/icons-react";
import { motion } from "framer-motion";
// import Image from "next/image";

export function BentoGridThirdDemo() {
  return (
    <div className=" bg-black w-full px-6 lg:px-0 h-fit md:h-[120vh] flex justify-center items-start mb-20">
      <BentoGrid className="max-w-4xl lg:max-w-5xl h-fit mx-auto my-auto md:auto-rows-[24rem] ">
        {items.map((item, i) => (
          <BentoGridItem
            key={i}
            title={item.title}
            description={item.description}
            header={item.header}
            className={cn("[&>p:text-lg]  ", item.className)}
            icon={item.icon}
          />
        ))}
      </BentoGrid>
    </div>
  );
}
const Skeleton = () => (
  <div className="flex flex-1 w-full h-full min-h-[12rem] rounded-xl   dark:bg-dot-white/[0.2] bg-dot-black/[0.2] [mask-image:radial-gradient(ellipse_at_center,white,transparent)]  border border-transparent dark:border-white/[0.2] bg-neutral-100 dark:bg-black"></div>
);

const SkeletonOne = () => {
  const variants = {
    initial: {
      x: 0,
    },
    animate: {
      x: 10,
      rotate: 5,
      transition: {
        duration: 0.2,
      },
    },
  };
  const variantsSecond = {
    initial: {
      x: 0,
    },
    animate: {
      x: -10,
      rotate: -5,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <motion.div
      initial="initial"
      whileHover="animate"
      className="flex flex-1 w-full h-full min-h-[10rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-col space-y-2 "
    >
      <motion.div className="h-full w-full rounded-lg overflow-hidden">
        <img src={ethicalAI} alt="" className="object-cover" />
      </motion.div>
    </motion.div>
  );
};
const SkeletonTwo = () => {
  const variants = {
    initial: {
      width: 0,
    },
    animate: {
      width: "100%",
      transition: {
        duration: 0.2,
      },
    },
    hover: {
      width: ["0%", "100%"],
      transition: {
        duration: 2,
      },
    },
  };
  const arr = new Array(6).fill(0);
  return (
    <motion.div
      initial="initial"
      animate="animate"
      whileHover="hover"
      className="flex flex-1 w-full h-full min-h-[10rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-col space-y-2  "
    >
      <motion.div className="h-full w-full rounded-lg overflow-hidden">
        <img src={proofreading} alt="" className="object-cover" />
      </motion.div>
    </motion.div>
  );
};
const SkeletonThree = () => {
  const variants = {
    initial: {
      backgroundPosition: "0 50%",
    },
    animate: {
      backgroundPosition: ["0, 50%", "100% 50%", "0 50%"],
    },
  };
  return (
    <motion.div
      initial="initial"
      animate="animate"
      variants={variants}
      transition={{
        duration: 5,
        repeat: Infinity,
        repeatType: "reverse",
      }}
      className="flex flex-1 w-full h-full min-h-[10rem] dark:bg-dot-white/[0.2] rounded-lg bg-dot-black/[0.2] flex-col space-y-2"
      style={{
        backgroundSize: "400% 400%",
      }}
    >
      <motion.div className="h-full w-full rounded-lg overflow-hidden">
        <img src={poverty} alt="" className=" object-cover " />
      </motion.div>
    </motion.div>
  );
};
const SkeletonFour = () => {
  const first = {
    initial: {
      x: 0,
      rotate: 0,
    },
    hover: {
      x: 0,
      rotate: 0,
    },
  };
  const second = {
    initial: {
      x: 0,
      rotate: 0,
    },
    hover: {
      x: 0,
      rotate: 0,
    },
  };
  return (
    <motion.div
      initial="initial"
      animate="animate"
      whileHover="hover"
      className="flex flex-1 w-full h-full min-h-[10rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-row space-x-2"
    >
      <motion.div className="h-full w-full rounded-lg flex justify-center">
        <img src={custom} alt="" className=" object-cover rounded-lg" />
      </motion.div>
    </motion.div>
  );
};
const SkeletonFive = () => {
  const variants = {
    initial: {
      x: 0,
    },
    animate: {
      x: 10,
      rotate: 5,
      transition: {
        duration: 0.2,
      },
    },
  };
  const variantsSecond = {
    initial: {
      x: 0,
    },
    animate: {
      x: -10,
      rotate: -5,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <motion.div
      initial="initial"
      whileHover="animate"
      className="flex flex-1 w-full h-full min-h-[10rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-col space-y-2"
    >
      <motion.div className="h-full w-full rounded-lg overflow-hidden">
        <img src={deployment} alt="" className="object-cover" />
      </motion.div>
    </motion.div>
  );
};
const items = [
  {
    title: "Impact Assessment Tools",
    description: (
      <span className="text-md">
        Evaluating the effectiveness and societal impact of data-driven and
        AI-powered initiatives.
      </span>
    ),
    header: <SkeletonTwo />,
    className: "md:col-span-1",
    icon: <IconFileBroken className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Tech for Good Resource Hub",
    description: (
      <span className="text-md">
        Offers valuable insights and guidance to clients and stakeholders for
        positive impact.
      </span>
    ),
    header: <SkeletonThree />,
    className: "md:col-span-1",
    icon: <IconSignature className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Ethical AI Guidelines and Resources",
    description: (
      <span className="text-md">
        Offering guidance and resources for developing AI solutions ethically
        and responsibly.{" "}
      </span>
    ),
    header: <SkeletonOne />,
    className: "md:col-span-1",
    icon: <IconClipboardCopy className="h-4 w-4 text-neutral-500" />,
  },

  {
    title: "Custom Research Projects",
    description: (
      <span className="text-md">
        Collaborating with companies to design and execute research projects
        tailored to their specific needs and objectives.{" "}
      </span>
    ),
    header: <SkeletonFour />,
    className: "md:col-span-2 ",
    icon: <IconTableColumn className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Machine Learning Model Deployment Platform",
    description: (
      <span className="text-md">
        Streamlining the deployment process of AI models into real-world
        applications.
      </span>
    ),
    header: <SkeletonFive />,
    className: "md:col-span-1 -white",
    icon: <IconTableColumn className="h-4 w-4 text-neutral-500" />,
  },
];
