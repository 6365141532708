import React, { useState } from "react";
import styles from "./page.module.scss";
import Project from "./Project/index";
import Modal from "./Modal/index";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./OurTeam.css";

const projects = [
  {
    title: "Aryan Verma",
    src: "c2montreal.png",
    color: "#000000",
    role: "CEO",
    uni: "The Unversity Of Edinburgh",
  },
  {
    title: "Dhruv Ghimiray",
    src: "officestudio.png",
    color: "#8C8C8C",
    role: " Full Stack Developer",
    uni: "Amity Unversity",
  },
  {
    title: "Mohit Kumar",
    src: "locomotive.png",
    color: "#EFE8D3",
    role: " Software Developer",
    uni: "NIT Hamirpur",
  },
  {
    title: "Manish Thakur",
    src: "silencio.png",
    color: "#706D63",
    role: "Front End Developer",
    uni: "NIT Hamirpur",
  },
  {
    title: "Roshni",
    src: "roshni.27d7e334ed1086d29f40-modified.png",
    color: "#706D63",
    role: "UI/UX Designer",
    uni: "",
  },
  {
    title: "Tanik Gandhi",
    src: "tanik.png",
    color: "#706D63",
    role: "Flutter Developer",
    uni: "DJSCE",
  },
];

const research = [
  {
    title: "Agnibha",
    src: "Agnibha-modified.png",
    color: "#000000",
    role: "Deep Learning Research Intern",
    uni: "UPES",
  },
  {
    title: "Rishi",
    src: "rishi.png",
    color: "#8C8C8C",
    role: "NLP Intern",
    uni: "IIT Madras",
  },
  {
    title: "Dheeraj",
    src: "dheeraj.png",
    color: "#EFE8D3",
    role: "NLP Intern",
    uni: "VVIT",
  },
];
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,

        display: "none",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    />
  );
}

const OurTeam = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    // slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="h-max w-full px-6 lg:px-auto section-team-member">
      <div className="h-22  ">
        <div className="flex flex-col items-center py-16 ">
          <span className=" bg-gradient-to-r from-orange-400 via-orange-700 to-orange-400 bg-clip-text text-transparent text-4xl lg:text-6xl font-bold">
            Meet Our Team
          </span>
        </div>
      </div>
      <div className="relative flex flex-col md:px-24 xl:flex-row items-center ">
      <div className=" w-full xl:w-1/4 h-44 p-4 relative  flex justify-center">
          <div className=" flex justify-start text-white">
            <div className="flex xl:flex-col">
              <span className="text-white text-xl lg:text-3xl font-bold">Core&nbsp;</span>

              <span className="text-white text-xl lg:text-3xl font-bold">Team</span>
            </div>
          </div>
        </div>
        <div className="w-full md:w-[95%] 2xl:w-[80%] md:w-4/4 h-80 p-4 relative ">
          <div className=" h-fit w-full text-white ">
            <div>
              <Slider {...settings}>
                {projects.map((project, index) => (
                  <div
                    key={project.name}
                    className=" h-fit text-white rounded-xl"
                  >
                    <div className=" h-48 m-4 mb-0 flex justify-center rounded-xl">
                      <img
                        src={`/image/${project.src}`}
                        alt=""
                        className=" h-44 w-44 rounded-full"
                      />
                    </div>

                    <p className="text-md text-center font-semibold bg-gradient-to-t from-orange-600 to-orange-500 bg-clip-text text-transparent  ">
                      {project.title}
                    </p>
                    <p className="text-center text-sm text-white">
                      {project.role}
                    </p>
                    <p className="text-center text-sm text-white">
                      {project.uni}
                    </p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col md:px-24 xl:flex-row items-center  ">
        <div className=" w-full xl:w-1/4 h-44 p-4 relative  flex justify-center">
          <div className=" flex justify-start items-center text-white">
            <div className="flex xl:flex-col">
              <span className="text-white text-xl lg:text-3xl font-bold">Research&nbsp;</span>
              <span className=" text-white text-xl lg:text-3xl font-bold">Team</span>
            </div>
          </div>
        </div>
        <div className="w-full md:w-[95%] 2xl:w-[80%] md:w-4/4 h-80 p-4 relative ">
          <div className=" h-fit w-full text-white ">
            <div>
              <Slider {...settings}>
                {research.map((project, index) => (
                  <div
                    key={project.name}
                    className=" h-fit text-white rounded-xl"
                  >
                    <div className=" h-48 m-4 mb-0 flex justify-center rounded-xl">
                      <img
                        src={`/image/${project.src}`}
                        alt=""
                        className=" h-44 w-44 rounded-full"
                      />
                    </div>

                    <p className="text-sm text-center font-semibold bg-gradient-to-t from-orange-600 to-orange-500 bg-clip-text text-transparent">
                      {project.title}
                    </p>
                    <p className="text-sm text-center text-white">
                      {project.role}
                    </p>
                    <p className="text-sm  text-center text-white">
                      {project.uni}
                    </p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
